export const getMenuItems = (state) => {
  const menuItemsCommon = [
    {
      label: "Users",
      subMenu: [
        {
          label: "Students",
          path: "/admin/user/students",
          permission: "manage-students.view",
        },
        {
          label: "Teachers",
          path: "/admin/user/teachers",
          permission: "manage-teachers.view",
        },
      ],
      permission: "manage-users.view",
      icon: "user-icon",
      className: "has-sub-menu",
    },
    {
      label: "Courses",
      path: "/admin/courses",
      permission: "manage-courses.view",
      icon: "course-icon",
      className: "",
    },
    {
      label: "Sections",
      path: "/admin/all-sections",
      permission: "manage-all-sections.view",
      icon: "all-section-icon",
      className: "",
    },
    {
      label: "Workshops",
      path: "/admin/work-shop",
      permission: "manage-work-shop.view",
      icon: "workshop-icon",
      className: "",
    },
    {
      label: "Attendance",
      path: "/admin/trainer-attendence",
      permission: "manage-trainer-attendence.view",
      icon: "atten-icon",
      className: "",
    },
    {
      label: "Finances",
      subMenu: [
        {
          label: "Transactions",
          path: "/admin/transactions",
          permission: "manage-transactions.view",
        },
        {
          label: "Organization",
          path: "/admin/organization",
          permission: "manage-organization.view",
        },
      ],
      permission: ["manage-transactions.view", "manage-organization.view"],
      icon: "finances-icon",
      className: "has-sub-menu",
    },
    {
      label: "Reports",
      path: "/admin/reports",
      permission: "manage-reports.view",
      icon: "reports_icon",
      className: "",
    },

    {
      label: "Settings",
      path: "/admin/setting",
      permission: "NR",
      icon: "setting-icon",
      className: "",
    },
    {
      label: "Cohorts",
      path: "/admin/cohorts",
      permission: "manage-cohorts.view",
      icon: "cohort-icon",
      className: "",
    },
    {


      label: "Support Tickets",
      path: "/admin/support-tickets",
      permission: "manage-support-ticket.view",
      icon: "cohort-icon",
      className: "",
      
      
    },
  ];
  const menuItemsAdmin = [
    {
      label: "Dashboard",
      path: "/admin",
      permission: "NR",
      icon: "dashboard-icon",
      className: "",
    },
    {
      label: "Job Board",
      subMenu: [
        {
          label: "Job Board",
          path: "/admin/job-board",
          permission: "manage-jobs-board.view",
        },
        {
          label: "Student Resume",
          path: "/admin/student-resume",
          permission: "manage-student-resume.view",
        },
        {
          label: "Job Record",
          path: "/admin/student-job-records",
          permission: "manage-job-record.view",
        },
      ],
      permission: [
        "manage-jobs-board.view",
        "manage-student-resume.view",
        "manage-job-record.view",
        "manage-student-applied-job.view",
        "manage-student-job-record.view",
      ],
      icon: "user-icon",
      className: "has-sub-menu",
    },
  ];
  const menuItemsOthers = [
    {
      label: "Sections",
      path: "/admin/sections",
      permission: "manage-sections.view",
      icon: "workshop-icon",
      className: "",
      condition: state.userRole === "Teacher",
    },
    {
      label: "Assignment",
      path: "/admin/student-assignment",
      permission: "manage-student-assignment.view",
      icon: "assign-icon",
      className: "",
      condition: state.userRole === "Student",
    },
    {
      label: "Attendance",
      path: "/admin/student-attendence",
      permission: "manage-student-attendance.view",
      icon: "atten-icon",
      className: "",
      condition: state.userRole === "Student",
    },
    {
      label: "Pre Assessment",
      path: "/admin/pre-assessment",
      permission: "manage-pre-assessment.view",
      icon: "assesment_icon",
      className: "",
      condition: state.userRole === "Student",
    },
    {
      label: "Post Assessment",
      path: "/admin/post-assessment",
      permission: "manage-post-assessment.view",
      icon: "assesment_icon",
      className: "",
      condition: state.userRole === "Student",
    },
    {
      label: "Job Board",
      subMenu: [
        {
          label: "Job Board",
          path: "/admin/jobs",
          permission: "manage-jobs-board.view",
          condition: state.userRole === "Student",
        },
        {
          label: "Applied Job",
          path: "/admin/applied-job",
          permission: "manage-student-applied-job.view",
          condition: state.userRole === "Student",
        },
        {
          label: "Job Record",
          path: "/admin/job-records",
          permission: "manage-student-job-record.view",
          condition: state.userRole === "Student",
        },
      ],
      permission: [
        "manage-jobs-board.view",
        "manage-student-resume.view",
        "manage-job-record.view",
        "manage-student-applied-job.view",
        "manage-student-job-record.view",
      ],
      icon: "user-icon",
      className: "has-sub-menu",
    },
  ];

  let orderedMenuItems = [];

  if (state.userRole && state.userRole.toLowerCase().includes("admin")) {
    orderedMenuItems = [...menuItemsCommon, ...menuItemsAdmin];
  } else {
    orderedMenuItems = [...menuItemsOthers, ...menuItemsCommon];
  }

  const customOrders = {
    "Super Admin": [
      "Dashboard",
      "Users",
      "Courses",
      "Cohorts",
      "Sections",
      "Attendance",
      "Workshops",
      "Finances",
      "Reports",
      "Job Board",
      "Support Tickets",
      "Settings",
    ],

    Teacher: [
      "Sections",
      "Users",
      "Courses",
      "Workshops",
      "Attendance",
      "Finances",
      "Reports",
      "Job Board",
      "Assignment",
      "Pre Assessment",
      "Post Assessment",
      "Support Tickets",
      "Settings",
    ],
    Student: [
      "Sections",
      "Attendance",
      "Assignment",
      "Pre Assessment",
      "Post Assessment",
      "Job Board",
      "Users",
      "Courses",
      "Workshops",
      "Attendance",
      "Finances",
      "Reports",
      "Support Tickets",
      "Settings",
    ],
  };

  let customOrder = [];

  // Check if the user role includes "admin" in lowercase
  if (state.userRole && state.userRole.toLowerCase().includes("admin")) {
    customOrder = customOrders["Super Admin"] || [];
  } else {
    customOrder = customOrders[state.userRole] || [];
  }

  // Sort the menu items based on custom order
  orderedMenuItems.sort((a, b) => {
    const indexA = customOrder.indexOf(a.label);
    const indexB = customOrder.indexOf(b.label);
    return indexA - indexB;
  });

  return orderedMenuItems;
};
