const AdminApi = {
  // ADMIN DASHBOARD
  ACTIVE_SCT_COUNT: "admin/student-courses-teachers-count",

  // PHASES API

  PHASES_ADD: "admin/phase/save/:id?",
  PHASES_VIEW: "admin/phases",
  PHASES_DELETE: "admin/delete-phase/:id",
  PHASES_EDIT: "admin/phase/view/:id",

  // SHIFTS API

  SHIFTS_ADD: "admin/shift/save/:id?",
  SHIFTS_PAGINATE: "admin/shifts",
  SHIFTS_DELETE: "admin/delete-shift/:id",
  SHIFTS_VIEW: "admin/shift/view/:id",

  // Gereral settings API

  GENERAL_ADD: "admin/settings/save/:id?",
  GENERAL_VIEW: "admin/settings",

  // ROLES MANAGEMENT API

  ROLES_SAVE: "admin/role/save/:id?",
  ROLES_PAGINATE: "admin/roles",
  ROLES_VIEW: "admin/role/view/:id",
  ROLES_DELETE: "admin/delete-role/:id",

  // CURRENCY API

  CURRENCY_ADD: "admin/currency/save/:id?",
  CURRENCY_VIEW: "admin/currencies",
  CURRENCY_VIEW_HISTORY: "admin/currency-history",
  CURRENCY_VIEW_BY_LOCATION: "admin/currencies-by-location/:id",
  CURRENCY_DELETE: "admin/delete-currency/:id",
  CURRENCY_EDIT: "admin/currency/view/:id",

  // CHANGE PASSWORD API

  CHANGE_PASSWORD: "admin/change-password",
  PASSWORD_RESET: "admin/student-change-password/:id",

  // ADMIN API

  ADMIN_SAVE: "admin/save/:id?",
  ADMIN_PAGINATE: "admin/admins",
  ADMIN_DELETE: "admin/delete-admin/:id",
  ADMIN_VIEW: "admin/view/:id",

  // FETCH ONLY ADMINS FROM ROLE API

  ADMIN_ROLE_PAGINATE: "admin/admin-roles",

  // LOCATION API

  LOCATION_SAVE: "admin/location/save/:id?",
  LOCATION_PAGINATE: "admin/locations",
  LOCATION_DELETE: "admin/delete-location/:id",
  LOCATION_VIEW: "admin/location/view/:id",

  // SPECIFIC CITY AND TIMEZONE API

  CITIES_PAGINATE: "admin/cities",
  TIMEZONE_PAGINATE: "admin/timezones",

  // COHORTS API

  COHORT_SAVE: "admin/AddCohort/:id?",
  COHORT_PAGINATE: "admin/FetchAllCohorts",
  COHORT_CSV: "admin/FetchAllCohortsCsv",
  COHORT_DELETE: "admin/DeleteCohort/:id",
  COHORT_VIEW: "admin/AllCohorts/:id",
  COHORT_ASSESSMENT_WEIGHTAGE: "admin/cohort-assessment/:id",

  // ADMIN USERS MODULE (STUDENTS)

  USER_MODULE_STUDENTS_SAVE: "admin/student/save/:id?",
  USER_MODULE_STUDENTS_DEL: "admin/student/:id",
  USER_MODULE_STUDENTS_PAGINATE: "admin/students",
  USER_MODULE_STUDENTS_ENROLL_HISTORY: "admin/student-section-history/",
  USER_MODULE_STUDENTS_ENROLL_HISTORY_CSV: "admin/section-history-csv",
  USER_MODULE_STUDENTS_DELETE: "admin/student/:id",
  USER_MODULE_STUDENTS_VIEW: "admin/students/:id",
  USER_MODULE_STUDENT_DOWNLOAD_CSV: "admin/studentsCSV",
  USER_MODULE_STUDENT_STATUS: "admin/user-status/:id",
  USER_MODULE_STUDENT_FLAG_STATUS: "admin/student-flag/:id",

  // ADMIN USER MODULE (TEACHER)

  USER_MODULE_TEACHER_PAGINATE: "admin/teachers",
  USER_MODULE_TEACHER_SAVE: "admin/Teacher/:id?",
  USER_MODULE_TEACHER_VIEW: "admin/SingleTeacher/:id",
  USER_MODULE_TEACHER_DELETE: "admin/teachers/:id",
  USER_MODULE_TEACHER_DOWNLOAD_CSV: "admin/teacherCSV",
  TEACHER_ACTIVE_INACTIVE: "admin/user-status/:id",

  // COURSES

  COURSES_MODULE_PAGINATE: "admin/courses",
  COURSES_MODULE_SAVE: "admin/course-content/:id?",
  COURSES_MODULE_VIEW: "admin/courses/:id",
  COURSES_MODULE_DELETE: "admin/course/:id",
  COURSES_MODULE_DOWNLOAD_CSV: "admin/coursescsv",
  COURSES_OUTLINE: "admin/course-outline/:id",
  COURSES_DETAIL_HEADER_VIEW: "admin/courses/view/:id",
  COURSE_SCORING_CRITERIA: "admin/course-criteria/:id",
  COURSE_ACTIVE_INACTIVE: "admin/course-status/:id",

  // SECTIONS

  SECTION_MODULE_SAVE: "admin/section/:id?",
  // SECTION_MODULE_EDIT: "admin/section/:id",
  SECTION_MODULE_VIEW: "admin/section/view/:id",
  SECTION_MODULE_DETAIL: "admin/sections-inner/view/:id",
  SECTION_MODULE_PAGINATION: "admin/sections/view/:id",
  ALL_SECTION_MODULE_PAGINATION: "admin/sections/view",
  ALL_SECTION_MODULE_CSV: "admin/sections/csv",
  SECTION_MODULE_DELETE: "admin/section-delete/:id",
  SECTION_ACTIVE_INACTIVE: "admin/section-status/:id",

  // ENROLLED
  ENROLLED_LEARNER_TOTAL_COUNT: "admin/sections/student-count/:id",
  ENROLLED_STUDENT_MODULE_SAVE: "admin/student-enroll",
  ENROLLED_STUDENT_MODULE_PAGINATION: "admin/get-student-enroll/:sectionid",
  ENROLLED_STUDENT_MODULE_DELETE: "admin/enrolled-delete/:id",

  // ASSESSMENT_WEIGHTAGE_TYPE

  ASSESSMENT_WEIGHTAGE_TYPE: "admin/assessment-weithage/:id",

  // ASSIGNMENT

  ASSIGNMENT_MODULE_SAVE: "admin/assignment/:id?",
  ASSIGNMENT_MODULE_VIEW: "admin/assignment/:id",
  ASSIGNMENT_MODULE_DETAIL: "admin/assignment-info/:id",
  ASSIGNMENT_MODULE_PAGINATION: "admin/assignments/:id",
  ASSIGNMENT_MODULE_DELETE: "admin/assignment-delete/:id",

  // ASSIGNMENT MARKS

  ASSIGNMENT_MARKS_MODULE_PAGINATION: "admin/assignment-marks/:id",

  // ATTENDANCE MODULE

  ATTENDANCE_MODULE_PAGINATION: "admin/attendance-view/:id",
  ATTENDANCE_MODULE_EDIT: "admin/GetStudentAttendance/:id",
  ATTENDANCE_MODULE_SAVE: "admin/UpdateStudentAttendance/:id",

  // GRADE BOOK MODULE

  GRADE_BOOK_MODULE_PAGINATION: "admin/grade-grid/:id",
  GRADE_BOOK_MODULE_INNER: "admin/grade-inner/:id",

  // SEARCH QUERIES

  SEARCH_NAME: "admin/search-enrolled",

  // SHIFTS

  SHIFTS: "admin/all-shifts",

  // COHORTS

  COHORTS: "admin/all-cohorts",

  // TEACHERS

  TEACHERS: "admin/all-teachers",

  // ORGANIZATION

  ORGANIZATIONS: "admin/organizations",

  // COUNTRIES

  COUNTRIES: "admin/countries",

  // CITIES

  CITIES: "admin/cities/:id?",

  // STATES

  STATES: "admin/states/:id",

  // SECTIONS

  SECTIONS: "admin/sections/:id?",
  SECTIONS_NO_ID: "admin/sections/with-no-cohort",
  ALL_SECTIONS: "admin/sections",

  // COHORTS

  COHORTS: "admin/cohorts",
  COURSES_LIST: "admin/all-courses",
  COURSES_LIST_BY_LOCATION: "admin/get-course-by-cohort/:id",
  COHORTS_LIST: "admin/courses-cohort/:id?",
  COHORTS_LIST_BY_LOCATION: "admin/location-cohort/:id",
  SECTIONS_LIST: "admin/cohort-sections/:id?",
  SECTIONS_LIST_BY_LOCATION: "admin/course-sections/:id",
  COURSE_COHORT_SECTIONS: "admin/course-cohort-sections",

  // Education level

  EDUCATION_LEVEL: "admin/qualifications",

  // Education Group

  EDUCATION_GROUP: "admin/qualificationfield",

  // student submited Assignment
  STUDENT_SUBMITED_ASSIGNMENT_EDIT: "admin/get-student-by-assignment/:id",
  STUDENT_SUBMITED_ASSIGNMENT_MARKED: "admin/UpdateAssignment/:id",
  STUDENT_SUBMITED_ASSIGNMENT_VIEW: "admin/AssignmentView/:id",

  // student submited Assignment
  SECTION_CRITERIA: "admin/section-criteria/:id",
  ENROLLED_STUDENT_STATUS: "admin/enrolled-status/:id",
  COHORT_STATUS: "admin/cohort-status/:id",

  // Transactions
  TRANSACTIONS_PAGINATION: "admin/get-transaction",
  TRANSACTIONS_SAVE: "admin/transaction/save",
  TRANSACTIONS_DETAIL: "admin/transaction-detail/view/:id",
  TRANSACTIONS_PREFILLED_DATA: "admin/search-transaction-student",
  TRANSACTIONS_VIEW: "admin/transaction-edit-prefilled/:id",
  TRANSACTIONS_UPDATE: "admin/update-transaction/:id",
  TRANSACTIONS_CSV: "admin/transactionCSV",
  TRANSACTIONS_DELETE: "admin/transaction-delete/:id",
  STUDENT_TRANSACTIONS_PAGINATION: "admin/student-transaction-detail/view/:id",
  STUDENT_TRANSACTIONS_REFUND: "admin/fee-refund",

  // Add Organization

  ORGANIZATION_PAGINATION: "admin/get-organizations",
  ORGANIZATION_DELETE: "admin/organization-delete/:id",
  ORGANIZATION_FUND_DELETE: "admin/transaction-delete/:id",
  ORGANIZATION_SAVE: "admin/organization/save/:id?",
  ORGANIZATION_DETAIL: "admin/get-organization-details/:id",
  ORGANIZATION_PREFILLED_DATA: "admin/search-transaction-student",
  ORGANIZATION_VIEW: "admin/transaction-edit-prefilled/:id",
  ORGANIZATION_UPDATE: "admin/update-transaction/:id",
  ORGANIZATION_CSV: "admin/organizationCSV",
  ORGANIZATION_TRANSACTIONS_CSV: "admin/organizationTransactionCSV/:id",
  ORGANIZATION_FUNDS_PAGINATION: "admin/organization-detail-transactions/:id",
  ORGANIZATION_FUNDS_HISTORY_PAGINATION:
    "admin/organization-transaction-grid/:id",
  ORGANIZATION_FUNDS_HISTORY_CSV: "admin/organizationTransactionCSV/:id",
  ORGANIZATION_EDIT: "admin/get-organization-transaction/:id",
  //   http://127.0.0.1:8000/admin/organization-transaction/save/:id -- transaction id (Post)

  // Organitation Funds
  ORGANIZATION_FUNDS: "admin/organization-transaction/save",
  ORGANIZATION_FUNDS_UPDATE: "admin/organization-transaction/save/:id",

  // COURSE OUTLINE
  COURSE_OUTLINE_PAGINATION: "admin/course-outline/:id",
  COURSE_OUTLINE_EDIT: "admin/course-media/:id",
  COURSE_OUTLINE_UPDATE: "admin/content-media/:id",
  COURSE_OUTLINE_VIEW: "admin/course-outline-edit/:id",
  COURSE_OUTLINE_DELETE: "admin/content-media-delete/:id",

  // SECTION COURSE OUTLINE
  SEC_COURSE_OUTLINE_PAGINATION: "admin/course-section-outline/:id",
  SEC_COURSE_OUTLINE_STATUS: "admin/course-section-status-update/:id",

  COURSE_OUTLINE_CSV: "admin/course-outline/:id",
  COURSE_SECTION_CSV: "admin/sections/view/:id",
  COURSE_SECTION_OUTLINE_CSV: "admin/course-section-outline/:id",
  COURSE_STUDENTS_ENROLLED_CSV: "admin/get-student-enroll/:id",
  COURSE_ASSIGNMENTS_CSV: "admin/assignments/:id",
  COURSE_ATTENDANCE_CSV: "admin/attendance-csv/:id",
  COURSE_GRADE_CSV: "admin/grade-grid/:id",

  // WORKSHOP MODULE
  WORKSHOP_EDIT: "admin/workshop/:id?",
  WORKSHOP_EDIT_WITH_IMAGE: "admin/upload-workshop-image/:id",
  WORKSHOP_VIEW: "admin/workshop/:id",
  WORKSHOP_PAGINATION: "admin/workshops",
  WORKSHOP_DELETE: "admin/workshop/:id",
  WORKSHOP_DROPDOWN: "admin/cohort-section",
  WORKSHOP_CSV: "admin/workshops-csv",

  //Workshop Attendence Detail
  WORKSHOP_ATTENDENCE_PAGINATION: "admin/students-by-workshop/:id",
  WORKSHOP_BANNER: "admin/workshop/:id",
  WORKSHOP_ATTENDENCE_VIEW: "admin/students-for-workshop/:id",
  WORKSHOP_ATTENDENCE_UPADATE: "admin/student-workshop-attendance/:id",
  WORKSHOP_DETAIL_CSV: "admin/students-by-workshop/:id",

  // PERMISSIONS

  MODULES_LIST: "admin/modules",
  PERMISSIONS_LIST: "admin/permissions",
  ROLES_PERMISSION_SAVE: "admin/permissions/save",
  ROLES_EDIT: "admin/role/view/:id",

  // JOB SKILL

  SKILLS_LISTING: "admin/skills",
  SKILLS_STATUS_STUDENTS: "admin/skill-status/:id",
  SKILL_SAVE: "admin/skill/:id?",
  SKILL_PREFILLED: "admin/edit-skill/:id",
  DELETE_SKILL: "admin/delete-skill/:id",

  // TEACHER_SECTION

  TEACHER_SECTION_MODULE_PAGINATION: "teacher/section-search",

  // STUDENT_JOB_RECORDS

  STUDENT_JOB_RECORDS_LIST: "admin/job-records",
  STUDENT_JOB_RECORDS_LIST_DELETE: "admin/delete-jobrecord/:id",
  ALL_STUDENT_JOB_RECORDS_SEARCH: "admin/job-record-student-search",

  // STUDENT_RESUME

  STUDENT_RESUMES: "admin/student-resume",
  STUDENT_RESUMES_DEL: "admin/delete-resume/:id",

  // Job Applicants
  JOBS_APPLICANTS: "admin/job-applicant/:id",

  // Student Profile
  PROFILE_SAVE: "student/create-student-profile",
  PROFILE_SAVE_FROM_ADMIN: "admin/update-student-profile/:id",
  PROFILE_VIEW: "student/student-profile",
  PROFILE_VIEW_ADMIN: "admin/student-profile/:id",
  PROFILE_SKILLS: "admin/skills",
  PROFILE_SKILLS_LEVELS: "student/skill-level",

  // JOBS

  JOBS_STATUS: "admin/job-status/:id",
  JOBS_SAVE: "admin/job/:id?",
  JOBS_APPLY: "student/upload-student-resume/:id",
  JOBS_VIEW: "admin/job/:id",
  JOBS_DETAIL_VIEW: "student/job-detail-view/:id",
  JOBS_LIST: "admin/jobs",
  JOBS_LIST_STUDENT: "student/fetch-jobs",
  ACTIVE_JOB_LIST: "admin/active-jobs",
  ACTIVE_JOB_PORTAL_COUNT: "admin/job-portal-count",
  JOBS_LIST_DETAIL: "admin/job/:id",
  JOBS_QUALIFICATIONS: "admin/qualifications",
  JOBS_SKILLS: "admin/skills",
  JOBS_SKILLS_LIST: "admin/skills-without-pagination",
  STUDENT_SKILLS_LIST: "admin/student-skills",

  // Dasboard Enrollments

  DASH_ENROLLMENTS: "admin/enrollments-count",
  DASH_LOCATION_ENROLLMENTS: "admin/location-enrollments-count",
  DASH_REGISTRATION_FEES: "admin/registration-fees",
  DASH_SELFPAID: "admin/self_paid_fees",
  DASH_LOCATION_REGISTRATION_FEES: "admin/location-registration-fees",
  DASH_LOCATION_SELFPAID: "admin/location-self_paid_fees",
  DASH_SPONSERSHIP_FUNDS: "admin/sponsorship-funds",
  DASH_SPONSER_COUNT: "admin/sponsors-count",
  DASH_ACTIVE_INACTIVE_STUDENT: "admin/active-inactive-students",
  DASH_TODAYS_CLASSES: "admin/today-classess",
  DASH_TOP_LOCATIONS: "admin/top-locations",
  DASH_TOP_NOTIFICATIONS: "admin/notifications",
  TEACHER_ATTENDENCE: "admin/teacher-attendance",
  TEACHER_ATTENDENCE_GRAPH: "admin/teacher-attendance-graph",

  CURRENCY_ADD_LIST: "admin/currencies",
  CURRENCY_RATE_UPDATE: "admin/currency-rate",
  CURRENCY_UPDATE_CHECK: "admin/currency-rate-check",

  COURSES_LIST_ATTENDENCE: "admin/get-teacher-course/:id?",
  COHORTS_LIST_ATTENDENCE: "admin/get-teacher-cohorts/:id?",
  COHORTS_SECTIONS_LIST: "admin/get-teacher-sections/:id?",

  STUDENT_REPORTS_FILTERS: "admin/student-reports-filters",
  REPORTS_COURSES: "admin/report-courses",
  REPORTS_COURSES_BY_COHORT: "admin/get-cohort-by-course/:id",
  REPORTS_SECTIONS_BY_COHORT: "admin/get-sections-by-cohorts/:id",
  GENRATE_STUDENT_REPORTS: "admin/student-report",

  // Support ticket
  SUPPORT_TICKET: "admin/support-ticket/:id?",
  SUPPORT_TICKET_ASSIGNTO: "admin/assign-to-admins",
  SUPPORT_TICKET_LIST: "admin/support-tickets",
  SUPPORT_TICKET_VIEW: "admin/edit-support-tickets/:id",
  SUPPORT_TICKET_VIEW_DETAIL: "admin/details-support-tickets/:id",
  SUPPORT_TICKET_COMMENT: "admin/add-comments/:id",
  SUPPORT_TICKET_ATTACH: "admin/upload-attachments/:id",
  SUPPORT_TICKET_ATTACH_GET: "admin/get-attachments/:id",


};

export default AdminApi;
